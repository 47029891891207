<template>
  <div
    class="innerContent"
    style="
      height: calc(100vh);
      width: 100%;
      min-width: 85%;
      max-width: 100%;
      background: var(--v-greyRaised-base) !important;
    "
  >
    <v-row class="ma-1 pa-2" no-gutters>
      <v-col cols="12" sm="12" md="4" lg="3">
        <v-row justify="center" class="pb-1 px-1">
          <v-col cols="12" sm="6" class="mr-0 pr-1">
            <v-card class="infoCards mr-0" flat style="height: 280px">
              <v-card-text class="ma-0 pa-0">
                <v-list dense class="my-0 py-0">
                  <v-subheader style="font-size: 14px">
                    <v-icon class="mr-2" small>tag</v-icon>
                    References <v-spacer></v-spacer>
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item v-if="booking.integrationReference"
                    ><v-list-item-content>
                      <v-list-item-title style="font-size: 12px">
                        {{ booking.integrationReference }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px">
                        Integration Ref No
                      </v-list-item-subtitle>
                    </v-list-item-content></v-list-item
                  >
                  <v-list-item
                    @click="
                      editReference(
                        'carrierReferenceNumber',
                        'Carrier Reference'
                      )
                    "
                    ><v-list-item-content>
                      <v-list-item-title style="font-size: 12px">
                        {{ booking.carrierReferenceNumber ?? "-" }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px">
                        Carrier Reference No
                      </v-list-item-subtitle>
                    </v-list-item-content></v-list-item
                  >
                  <v-list-item
                    class="mt-0 pt-0"
                    @click="
                      editReference('shipperReferenceNo', 'Shipper Reference')
                    "
                    ><v-list-item-content>
                      <v-list-item-title style="font-size: 12px">
                        {{ booking.shipperReferenceNo ?? "-" }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px">
                        Shipper Ref No
                      </v-list-item-subtitle>
                    </v-list-item-content></v-list-item
                  >
                  <v-list-item
                    class="mt-0 pt-0"
                    @click="
                      editReference(
                        'consigneeReferenceNo',
                        'Consignee Reference'
                      )
                    "
                    ><v-list-item-content>
                      <v-list-item-title style="font-size: 12px">
                        {{ booking.consigneeReferenceNo ?? "-" }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px">
                        Consignee Ref No
                      </v-list-item-subtitle>
                    </v-list-item-content></v-list-item
                  >
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" class="ml-0 pl-1">
            <v-card
              class="infoCards ml-0"
              flat
              style="height: 280px"
            >
              <v-card-text class="ma-0 pa-0">
                <v-list dense subheader class="mx-0 px-0" v-if="booking.stackDate">
                  <v-subheader style="font-size: 14px">
                    <v-icon class="mr-2" small>event</v-icon>
                    Stack Dates
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >Reefer Dates</v-list-item-title
                      >
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="booking.stackDate.reeferStackStart"
                        class="mt-1"
                      >
                        Open:
                        <v-icon
                          x-small
                          :color="
                            booking.stackDate.reeferStackStartConfirmed
                              ? 'success'
                              : 'orange'
                          "
                          >fiber_manual_record</v-icon
                        >
                        {{
                          $Format.formatDate(booking.stackDate.reeferStackStart)
                            .dateTimeDefault
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="booking.stackDate.reeferStackFinish"
                        class="mt-1"
                      >
                        Close:
                        <v-icon
                          x-small
                          :color="
                            booking.stackDate.reeferStackFinishConfirmed
                              ? 'success'
                              : 'orange'
                          "
                          >fiber_manual_record</v-icon
                        >
                        {{
                          $Format.formatDate(
                            booking.stackDate.reeferStackFinish
                          ).dateTimeDefault
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >Dry Dates</v-list-item-title
                      >
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="booking.stackDate.dryStackStart"
                        class="mt-1"
                      >
                        Open:
                        <v-icon
                          x-small
                          :color="
                            booking.stackDate.dryStackStartConfirmed
                              ? 'success'
                              : 'orange'
                          "
                          >fiber_manual_record</v-icon
                        >
                        {{
                          $Format.formatDate(booking.stackDate.dryStackStart)
                            .dateTimeDefault
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="booking.stackDate.dryStackFinish"
                        class="mt-1"
                      >
                        Close:
                        <v-icon
                          x-small
                          :color="
                            booking.stackDate.dryStackFinishConfirmed
                              ? 'success'
                              : 'orange'
                          "
                          >fiber_manual_record</v-icon
                        >
                        {{
                          $Format.formatDate(booking.stackDate.dryStackFinish)
                            .dateTimeDefault
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >FIS Dates</v-list-item-title
                      >
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="booking.stackDate.fisStart"
                        class="mt-1"
                      >
                        Open:
                        <v-icon
                          x-small
                          :color="
                            booking.stackDate.fisStartConfirmed
                              ? 'success'
                              : 'orange'
                          "
                          >fiber_manual_record</v-icon
                        >
                        {{
                          $Format.formatDate(booking.stackDate.fisStart)
                            .dateTimeDefault
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="booking.stackDate.fisFinish"
                        class="mt-1"
                      >
                        Close:
                        <v-icon
                          x-small
                          :color="
                            booking.stackDate.fisFinishConfirmed
                              ? 'success'
                              : 'orange'
                          "
                          >fiber_manual_record</v-icon
                        >
                        {{
                          $Format.formatDate(booking.stackDate.fisFinish)
                            .dateTimeDefault
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list dense subheader class="mx-0 px-0" v-else>
                  <v-subheader style="font-size: 14px">
                    <v-icon class="mr-2" small>event</v-icon>
                    Stack Dates
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey">No Stack Dates Available</span>
                      </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-card class="infoCards ma-1" flat>
          <v-card-text class="ma-0 pa-0">
            <Locations
              :booking="booking"
              :countries="countries"
              @bookingChange="$emit('bookingChange')"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="3">
        <v-card class="infoCards mx-1" flat>
          <v-card-text class="ma-0 pa-0">
            <Parties
              :booking="booking"
              :organisations="organisations"
              @bookingChange="$emit('bookingChange')"
            />
          </v-card-text>
        </v-card>
       

        <!-- <v-card class="infoCards mx-1 mt-2 pt-1" flat>
          <v-card-text class="ma-0 pa-0">
            <TransportDetail :booking="booking" />
          </v-card-text>
        </v-card>
        <v-card class="infoCards mx-1 mt-2 pt-1" flat>
          <v-card-text class="ma-0 pa-0">
            <v-list dense class="my-0 py-0" subheader :key="transportKey">
              <v-subheader style="font-size: 14px">
                <v-icon class="mr-2" small>local_shipping</v-icon>Transport
                Locations
              </v-subheader>
              <v-divider></v-divider>
              <v-list-item
                v-for="(location, index) in transportLocations"
                :key="index"
                style="height: 30px"
              >
                <v-list-item-action>
                  <v-chip small>{{ location.count }}</v-chip>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    {{ location.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 11px">
                    {{ location.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="transportLocations.length == 0">
                <v-list-item-content class="text-center">
                  <span style="color: grey; font-size: 11px"
                    >No locations set</span
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card> -->
      </v-col>

      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row justify="center" class="pb-1 px-1">
          <v-col cols="12" sm="6" class="mr-0 pr-1">
            <v-card class="infoCards mx-0 mb-0" flat>
              <v-card-text class="ma-0 pa-0">
                <v-list dense class="my-0 py-0" subheader>
                  <v-subheader style="font-size: 14px">
                    <v-icon class="mr-2" small>tune</v-icon>Cargo Rules
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item style="height: 30px" v-if="booking.isHazardous">
                    <v-list-item-action class="mr-1">
                      <v-icon small>warning</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px">
                        Hazardous Content
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon class="pulse-hazardous" color="#0e871e"
                        >error</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item @click="caProductModal = true">
                    <v-list-item-action class="mr-1">
                      <v-icon small>thermostat_auto</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        v-if="booking.caProduct"
                        style="font-size: 12px"
                      >
                        {{ booking.caProduct }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="booking.oxygen || booking.carbonDioxide"
                      >
                        <span v-if="booking.oxygen" style="font-size: 11px">
                          <span
                            style="font-weight: bold; font-size: 11px"
                            class="ml-2"
                            >O²</span
                          >
                          {{ booking.oxygen }}%</span
                        >
                        <span
                          v-if="booking.carbonDioxide"
                          style="font-size: 11px"
                        >
                          <span
                            style="font-weight: bold; font-size: 11px"
                            class="ml-2"
                            >CO²</span
                          >
                          {{ booking.carbonDioxide }}%</span
                        >
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 11px">
                        Controlled Atmosphere
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item :key="regimeKey" @click="getRegimeCodes(true)">
                    <v-list-item-action class="mr-1">
                      <v-icon small>code</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size: 12px"
                        v-if="booking.regime"
                      >
                        {{ booking.regime.code }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>

                      <v-list-item-subtitle style="font-size: 11px">
                        Regime Code
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="booking.regime && booking.regime.setPointTemp"
                      >
                        <v-icon color="#007d5c" x-small class="mr-1"
                          >thermostat</v-icon
                        >
                        Set Temp:
                        {{ `${booking.regime.setPointTemp}°C` }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="booking.regime && booking.regime.protocol"
                      >
                        <v-icon color="#9d904a" x-small class="mr-1"
                          >thermostat</v-icon
                        >
                        Protocol:
                        {{ `${booking.regime.protocol}°C` }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="booking.regime">
                    <v-list-item-action class="mr-1">
                      <v-icon small>air</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px">
                        Vent Settings
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-menu
                        offset-y
                        rounded
                        v-if="
                          !booking.caProduct ||
                          (booking.caProduct && booking.caProduct == 'MAXTEND')
                        "
                      >
                        <template v-slot:activator="{ on }">
                          <v-chip v-on="on" small outlined label>
                            <span style="text-transform: none">
                              {{ booking.vents }}
                            </span>
                            <span v-if="!isNaN(booking.vents)" class="ml-1"
                              >CBM</span
                            >
                            <v-icon small right>expand_more</v-icon>
                          </v-chip>
                        </template>
                        <v-list dense subheader>
                          <v-list-item
                            v-for="(vent, index) in ventSettings"
                            :key="index"
                            @click="booking.vents = vent"
                          >
                            <v-list-item-content>
                              <v-list-item-title>{{ vent }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-chip v-else small outlined label> Closed </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
            <v-card class="infoCards mx-0 mt-2 pt-1" flat>
              <v-card-text class="ma-0 pa-0">
                <v-list dense class="my-0 py-0" subheader :key="containerKey">
                  <v-subheader style="font-size: 14px">
                    <v-icon class="mr-2" small>leaderboard</v-icon>Cargo Summary
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item
                    v-if="booking.teuCarbonEmission"
                    style="height: 45px"
                  >
                    <v-list-item-action>
                      <v-icon color="lime darken-1" large>compost</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px">
                        Total:
                        {{ calculateTotalEmissions(booking.teuCarbonEmission) }}
                        KG CO²
                      </v-list-item-title>
                      <v-list-item-title style="font-size: 11px">
                        Carbon Emission
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="cargoSummary().length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 14px">No cargo</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="(cargo, index) in cargoSummary()"
                    :key="index"
                  >
                    <v-list-item-action class="mr-1">
                      <v-chip small>{{ cargo.count }}x</v-chip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px">
                        {{ cargo.products }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px">
                        Pallets: {{ cargo.pallets }} | Units: {{ cargo.units }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="booking.serviceType != 'BREAKBULK'"
                      >
                        <span
                          class="mr-1"
                          v-if="cargo.containerType"
                          style="font-size: 11px"
                        >
                          {{ cargo.containerType }}
                        </span>
                        <span
                          v-if="booking.movementType == 'EXPORT'"
                          style="font-size: 11px"
                        >
                          - {{ cargo.method }}
                        </span>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle style="font-size: 11px">
                        <!-- <span class="mr-1">Transport: </span>  -->
                        <div v-if="cargo.transportType == 'Merchant'">
                          <v-icon x-small color="grey" class="mr-1">
                            local_shipping
                          </v-icon>
                          <span
                            v-if="cargo.transportCoordinator"
                            style="font-size: 11px"
                          >
                            Transport Coordinator:
                            <span
                              v-if="cargo.transportCoordinator.alias"
                              style="font-size: 11px"
                            >
                              {{ cargo.transportCoordinator.alias }}
                            </span>
                            <span v-else style="font-size: 11px">
                              {{ cargo.transportCoordinator.name }}
                            </span>
                          </span>
                          <span v-else>No Transport Coordinator</span>
                        </div>
                        <div
                          v-else-if="cargo.transportType == 'Carrier'"
                          style="font-size: 11px"
                        >
                          <v-icon color="grey" x-small class="mr-1"
                            >directions_boat</v-icon
                          >
                          Carrier Haulage
                        </div>
                        <div v-else-if="cargo.transportType == 'Rail'">
                          <v-icon color="grey" small class="mr-1">train</v-icon>
                          <span
                            v-if="cargo.transportCoordinator"
                            style="font-size: 11px"
                          >
                            Transport Coordinator:
                            <span
                              v-if="cargo.transportCoordinator.alias"
                              style="font-size: 11px"
                            >
                              {{ cargo.transportCoordinator.alias }}
                            </span>
                            <span v-else style="font-size: 11px">
                              {{ cargo.transportCoordinator.name }}
                            </span>
                          </span>
                        </div>
                        <div v-else style="font-size: 11px">
                          <v-icon color="grey" small class="mr-1">help</v-icon>
                          Transport not specified
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" class="ml-0 pl-1">
            <v-card class="infoCards mx-0 mb-0" flat>
              <v-card-text class="ma-0 pa-0">
                <v-list dense class="my-0 py-0" subheader>
                  <v-subheader style="font-size: 14px">
                    <v-icon class="mr-2" small>notifications</v-icon>Subscribers
                    <v-btn icon color="primary" @click="editSubscribers()">
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item v-if="booking.subscribers.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px">
                        No subscribers
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    style="height: 30px"
                    v-for="user in booking.subscribers"
                    :key="user.id"
                  >
                    <v-list-item-action class="mr-2">
                      <v-avatar
                        :color="user.user.avatar ? 'white' : 'secondary'"
                        size="28"
                      >
                        <v-img
                          contain
                          v-if="user.user.avatar"
                          :src="user.user.avatar"
                        ></v-img>
                        <h3 v-else>
                          {{ user.user.firstname.charAt(0) }}
                        </h3>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ user.user.firstname }} {{ user.user.surname }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px">
                        {{ user.user.emailAddress }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            icon
                            color="redPop"
                            @click="removeSubscriber(user.id)"
                            :loading="removingSubscriber == user.id"
                          >
                            <v-icon small>delete</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Remove subscriber</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
            <v-card class="infoCards mt-2 mx-0 mb-0" flat>
              <v-card-text class="ma-0 pa-0">
                <v-list dense class="my-0 py-0" subheader>
                  <v-subheader style="font-size: 14px">
                    <v-icon class="mr-2" small>checklist</v-icon>Tasks
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item
                    v-if="
                      !booking.integrationReference &&
                      (booking.type == 'LOGISTICS' ||
                        booking.type == 'FREIGHT') &&
                      booking.state != 'Cancelled' &&
                      booking.modeOfTransport == 'OCEAN'
                    "
                    @click="checkSubmitBooking()"
                  >
                    <v-list-item-action class="mr-1 pr-2">
                      <v-avatar size="40" color="white">
                        <v-img
                          class="pa-2"
                          contain
                          src="https://cdn.loglive.io/integrations/inttra-vector-logo.png"
                        ></v-img>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Submit Booking to Inttra
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px">
                        Creates & submits this booking data
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      booking.integrationChange &&
                      booking.integrationReference &&
                      (booking.type == 'LOGISTICS' ||
                        booking.type == 'FREIGHT') &&
                      booking.state != 'Cancelled' &&
                      booking.modeOfTransport == 'OCEAN'
                    "
                    @click="checkSubmitUpdateBooking()"
                  >
                    <v-list-item-action class="mr-1 pr-2">
                      <v-avatar size="40" color="white">
                        <v-img
                          class="pa-2"
                          contain
                          src="https://cdn.loglive.io/integrations/inttra-vector-logo.png"
                        ></v-img>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Update Inttra Booking
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px">
                        Submits updated information to Inttra
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="booking.integrationReference"
                        @click="copyContent(booking.integrationReference)"
                        style="font-size: 11px; cursor: pointer"
                      >
                        <v-icon x-small class="mr-1">content_copy</v-icon>
                        Reference:
                        {{ booking.integrationReference }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="booking.integrationReference"
                    @click="confirmCancel = true"
                  >
                    <v-list-item-action class="mr-1 pr-2">
                      <v-avatar size="40" color="white">
                        <v-img
                          class="pa-2"
                          contain
                          src="https://cdn.loglive.io/integrations/inttra-vector-logo.png"
                        ></v-img>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Cancel Booking </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="!booking.q67"
                    @click="viewQ67()"
                    :key="exportNotificationKey"
                  >
                    <v-list-item-action class="mr-1 pr-2">
                      <v-avatar size="40" color="secondary">
                        <h3>Q67</h3>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Generate Q67 / Export Notification
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px">
                        Generates the document & send the notification to Titan
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else>
                    <v-list-item-action class="mr-1 pr-2">
                      <v-avatar size="40" color="secondary">
                        <h3>Q67</h3>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Q67 / Export Notification
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px">
                        Version {{ booking.q67.version }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="booking.exportNotificationStatus"
                      >
                        <v-icon
                          class="mr-1"
                          x-small
                          :color="
                            booking.exportNotificationStatus == 'PENDING'
                              ? 'orange'
                              : booking.exportNotificationStatus == 'INPROGRESS'
                              ? 'blue'
                              : booking.exportNotificationStatus == 'COMPLETED'
                              ? 'success'
                              : 'red'
                          "
                        >
                          fiber_manual_record
                        </v-icon>
                        {{
                          formatExportNotificationStatus(
                            booking.exportNotificationStatus
                          )
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="booking.exportNotificationReference"
                        @click="
                          copyContent(booking.exportNotificationReference)
                        "
                        style="font-size: 11px; cursor: pointer"
                      >
                        <v-icon x-small class="mr-1">content_copy</v-icon>
                        Reference:
                        {{ booking.exportNotificationReference }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-menu offset-y offset-x nudge-left="30" rounded>
                        <template v-slot:activator="{ on }">
                          <v-chip v-on="on" small outlined label>
                            Actions
                            <v-icon small right>expand_more</v-icon>
                          </v-chip>
                        </template>
                        <v-list dense subheader>
                          <v-subheader style="font-size: 14px"
                            >Actions</v-subheader
                          >
                          <v-divider></v-divider>
                          <v-list-item
                            style="height: 30px"
                            @click="viewDocument(booking.q67)"
                          >
                            <v-list-item-action class="mr-1">
                              <v-icon small>launch</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                >View Document</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            style="height: 30px"
                            @click="downloadDocument(booking.q67)"
                          >
                            <v-list-item-action class="mr-1">
                              <v-icon small>download</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                >Download Q67 (PDF)</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            style="height: 30px"
                            @click="downloadDocument(booking.q67Excel)"
                          >
                            <v-list-item-action class="mr-1">
                              <v-icon small>browser_updated</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                >Download Q67 (Excel)</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item style="height: 30px" @click="viewQ67()">
                            <v-list-item-action class="mr-1">
                              <v-icon small>post_add</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                >Generate New Q67</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            v-if="booking.exportNotificationData"
                            style="height: 30px"
                            @click="
                              viewExportNotificationData(
                                booking.exportNotificationData
                              )
                            "
                          >
                            <v-list-item-action class="mr-1">
                              <v-icon small>data_object</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                >View PPECB Export Notification
                                Data</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item
                            v-if="booking.exportNotificationReference"
                            style="height: 30px"
                            @click="confirmExportNotificationItems()"
                          >
                            <v-list-item-action class="mr-1">
                              <v-icon small>verified</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                >Confirm Export Notification
                                Items</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <!-- <v-container
                        v-if="booking.q67"
                        style="width: 100%; height: 100%"
                      >
                        <v-row justify="start" align="center">
                          <v-tooltip top v-if="booking.q67Excel">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                @click="downloadDocument(booking.q67Excel)"
                                v-on="on"
                                color="green"
                              >
                                <v-icon small>browser_updated</v-icon>
                              </v-btn>
                            </template>
                            <span style="font-size: 12px"
                              >Download Excel Q67</span
                            >
                          </v-tooltip>
                         
                        </v-row>
                      </v-container> -->
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
            <v-card class="infoCards mt-2 mx-0 mb-0" flat>
              <v-card-text class="ma-0 pa-0">
                <v-list dense class="my-0 py-0" subheader>
                  <v-subheader style="font-size: 14px">
                    <v-icon class="mr-2" small>article</v-icon>Files
                    <v-menu
                      v-if="
                        (booking.state !== 'Confirmed' &&
                        booking.state !== 'Futile')
                      "
                      open-on-hover
                      bottom
                      left
                      offset-x
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon color="grey">
                          <v-icon small>add_circle_outline</v-icon>
                        </v-btn>
                      </template>
                      <v-list
                        dense
                        subheader
                        style="
                          background: var(--v-component-base) !important;
                          background-color: var(--v-component-base) !important;
                        "
                      >
                        <v-subheader style="font-size: 14px"
                          >Validation Errors</v-subheader
                        >
                        <v-divider></v-divider>
                        <v-list-item
                          style="height: 30px"
                        >
                          <v-list-item-action class="mr-1">
                            <v-icon small color="redPop"
                              >fiber_manual_record</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 12px"
                              >Files can only be created when booking status is
                              "Confirmed"</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>

                      </v-list>
                    </v-menu>
                    <v-btn
                      icon
                      v-else
                      color="primary"
                      @click="viewFileDialog()"
                    >
                      <v-icon>add_circle_outline</v-icon></v-btn
                    >
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item v-if="linkedFiles.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No linked files</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="file in linkedFiles"
                    :key="file.id"
                    @click="viewShipmentFile(file)"
                  >
                    <v-list-item-action class="mx-0">
                      <v-icon
                        v-if="file.status == 'Processed'"
                        small
                        color="success"
                        >task_alt</v-icon
                      >
                      <v-icon
                        v-else-if="file.status == 'Ready'"
                        small
                        color="blue"
                        >verified</v-icon
                      >
                      <v-icon
                        v-else-if="file.status == 'Cancelled'"
                        color="red"
                        small
                        >cancel</v-icon
                      >
                      <v-icon v-else color="orange" small>edit_note</v-icon>
                    </v-list-item-action>
                    <v-list-item-content class="ml-0">
                      <v-list-item-title>
                        {{ file.fileNumber }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
            <v-card class="infoCards mx-1 mt-2" flat>
          <v-card-text class="ma-0 pa-0">
            <v-list dense class="my-0 py-0" subheader>
              <v-subheader style="font-size: 14px">
                <v-icon class="mr-2" small>sell</v-icon>Product Sales
                <v-btn icon color="primary" @click="addProductService">
                  <v-icon>add_circle_outline</v-icon>
                </v-btn>
              </v-subheader>
              <v-divider></v-divider>
              <v-list-item
                v-if="
                  !booking.productServiceSales ||
                  booking.productServiceSales.length == 0
                "
              >
                <v-list-item-content class="text-center">
                  <span style="color: grey; font-size: 12px"
                    >No products listed</span
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="product in booking.productServiceSales"
                :key="product.id"
              >
                <v-list-item-action class="mr-1">
                  <v-avatar
                    class="mr-2"
                    size="30"
                    :color="!product.productService.icon ? 'secondary' : ''"
                  >
                    <v-img
                      v-if="product.productService.icon"
                      :src="product.productService.icon"
                      contain
                    ></v-img>
                    <h3 v-else>{{ product.productService.name.charAt(0) }}</h3>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 12px"
                    class="text-wrap"
                    >{{ product.productService.name }}</v-list-item-title
                  >
                  <v-list-item-subtitle
                    style="font-size: 11px"
                    v-if="product.productService.type == 'PRODUCT'"
                  >
                    <v-icon color="grey" class="mr-1 mb-1" small
                      >category</v-icon
                    >
                    Product</v-list-item-subtitle
                  >
                  <v-list-item-subtitle style="font-size: 11px" v-else>
                    <v-icon color="grey" class="mr-1 mb-1" small
                      >engineering</v-icon
                    >
                    Service</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="text-wrap" style="font-size: 11px" v-if="product.productService.productServiceCategory && product.productService.productServiceSubcategory">
                    {{ product.productService.productServiceCategory.name }} <v-icon x-small>chevron_right</v-icon> {{ product.productService.productServiceSubcategory.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small>{{ product.quantity }}</v-chip>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog
      v-model="referenceModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card
        v-if="referenceType"
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            {{ referenceType.label }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="referenceModal = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            autofocus
            v-model="booking[referenceType.field]"
            outlined
            dense
            clearable
            @change="$emit('bookingChange')"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="caProductModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"
            >Set Controlled Atmosphere</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="caProductModal = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-autocomplete
            v-model="booking.caProduct"
            :items="caProducts"
            outlined
            dense
            placeholder="Select a CA Product"
            clearable
            @change="setCAProduct()"
            item-text="description"
            item-value="name"
          ></v-autocomplete>
          <v-text-field
            label="O²"
            outlined
            dense
            v-model="booking.oxygen"
            type="number"
            @input="setCASettings()"
            suffix="%"
          ></v-text-field>
          <v-text-field
            label="CO²"
            outlined
            dense
            v-model="booking.carbonDioxide"
            type="number"
            @input="setCASettings()"
            suffix="%"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="regimeCodeModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
      peristent
    >
      <v-card
        :loading="regimeCodeLoading"
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            Manage Regime Code
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top v-if="booking.regime">
            <template v-slot:activator="{ on }">
              <v-btn icon color="danger" v-on="on" @click="removeRegime">
                <v-icon small> delete </v-icon>
              </v-btn>
            </template>
            <span>Remove Regime Code</span>
          </v-tooltip>
          <v-btn icon color="redPop" @click="regimeCodeModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            placeholder="Search"
            autofocus
            prpend-inner-icon="search"
            v-model="regimeCodeSearch"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
          <v-virtual-scroll
            v-if="filterRegimeCodes.length > 0"
            :bench="0"
            :items="filterRegimeCodes"
            height="500"
            item-height="75"
          >
            <template v-slot:default="{ item }">
              <v-list-item
                @click="setRegimeCode(item)"
                style="border-bottom: 0.5px solid grey"
              >
                <v-list-item-content>
                  <v-list-item-title style="font-size: 13px">
                    {{ item.code }}
                    <v-chip class="ml-2" outlined small v-if="item.steri">
                      <v-icon left color="blue" small>ac_unit</v-icon>Steri Code
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px">
                    <v-icon class="mr-2" color="grey" small>thermostat</v-icon
                    >Set Point: {{ item.setPointTemp }}°C
                    <v-icon class="mx-2" color="red" small>thermostat</v-icon
                    >Max: {{ item.maxProductTemp }}°C
                    <v-icon class="mx-2" color="blue" small>thermostat</v-icon
                    >Min {{ item.minProductTemp }}°C
                  </v-list-item-subtitle>
                  <v-list-item-subtitle style="font-size: 12px">
                    {{ item.recipeDescription }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="filterRegimeCodes.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 13px"
                  >No regime codes found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="productServiceModal" width="1500px">
      <ProductServiceModal @close="productServiceModal=false" :openKey="productKey" :enableSave="true" :properties="{bookingId: booking.id}" @selected="setProductSelection" :level="productServiceItem.level" :preselected="booking.productServiceSales"/>
      <!-- <v-card
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 16px"
            >Add Products & Services</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn @click="productServiceModal = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="mt-2"
            hide-details
            placeholder="Search"
            v-model="searchBookingProduct"
            dense
            outlined
            clearable
          ></v-text-field>
          <v-list dense class="my-1 py-0" :key="selectedProductKey">
            <v-list-item
              v-for="product in filterAvailableShipmentProducts"
              :key="product.id"
              @click="selectBookingProduct(product)"
              :style="{
                'border-left': product.selected
                  ? '4px solid var(--v-success-base)'
                  : '4px solid transparent',
              }"
            >
              <v-list-item-action class="mr-1">
                <v-avatar
                  class="mr-2"
                  size="30"
                  :color="!product.icon ? 'secondary' : ''"
                >
                  <v-img
                    v-if="product.icon"
                    :src="product.icon"
                    contain
                  ></v-img>
                  <h3 v-else>{{ product.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px" class="text-wrap">{{
                  product.name
                }}</v-list-item-title>
                <v-list-item-subtitle
                  style="font-size: 11px"
                  v-if="product.type == 'PRODUCT'"
                >
                  <v-icon color="grey" class="mr-1 mb-1" small>category</v-icon>
                  Product</v-list-item-subtitle
                >
                <v-list-item-subtitle style="font-size: 11px" v-else>
                  <v-icon color="grey" class="mr-1 mb-1" small
                    >engineering</v-icon
                  >
                  Service</v-list-item-subtitle
                >
                <v-list-item-subtitle style="font-size: 11px" class="text-wrap">
                  {{ product.description }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="product.selected" color="success"
                  >check_circle</v-icon
                >
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="filterAvailableShipmentProducts.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No products found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card> -->
    </v-dialog>

    <v-dialog
      v-model="bookingSubmitWarning"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-card-text class="text-left mt-3 pt-3">
          <p style="font-size: 14px">
            Please select make sure to complete the following before continuing:
          </p>
          <ul>
            <li v-if="!booking.forwarder">Select a freight Forwarder</li>
            <li v-if="!booking.shipper">Select a Shipper</li>
            <li v-if="!booking.contractNumber">
              Provide a shipping line contract number
            </li>
            <li v-if="!booking.finalDestinationValue">
              Select a final destination
            </li>
            <li v-if="!booking.portOfLoadValue">Select a port of load</li>
            <li v-if="!booking.vessel">Select a vessel</li>
            <li v-if="!booking.mainCarriageConveyanceNumber">
              Select a voyage
            </li>
            <li v-if="!booking.regime && reeferShipment">
              Select a regime code
            </li>
            <li v-if="checkContainerCargo">No containers/cargo listed</li>
            <li v-if="checkContainerProducts">No products listed</li>
          </ul>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="blue"
              class="mx-2"
              text
              small
              @click="bookingSubmitWarning = false"
              >Okay</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bookingUserModal" width="500px" persistent>
      <v-card
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            Add Subscribers
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            :disabled="selectedBookingUsers.length == 0"
            @click="saveBookingSubscriber()"
            :loading="savingBookingUser"
          >
            <v-icon small>save</v-icon>
          </v-btn>
          <v-btn icon color="redPop" @click="bookingUserModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="bookingUserSearch"
            outlined
            dense
            hide-details
            placeholder="Search"
            prepend-inner-icon="search"
            clearable
          ></v-text-field>
          <v-list
            dense
            style="
              max-height: 50vh;
              overflow-y: auto;
              background: transparent;
              background-color: transparent;
            "
          >
            <v-list-item v-if="filteredUsers.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey">No users found</span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="user in filteredUsers"
              :key="user.id"
              @click="addBookingUser(user.userId)"
              style="height: 30px"
            >
              <v-list-item-action>
                <v-avatar
                  :color="user.user.avatar ? 'white' : 'secondary'"
                  size="36"
                >
                  <v-img
                    v-if="user.user.avatar"
                    referrerpolicy="no-referrer"
                    :src="user.user.avatar"
                    contain
                  ></v-img>
                  <h3 v-else-if="user.user.firstname">
                    {{ user.user.firstname.charAt(0) }}
                  </h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ user.user.firstname }} {{ user.user.surname }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                  v-if="selectedBookingUsers.includes(user.userId)"
                  color="green"
                  >check_circle_outline</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="q67CommentModal"
      width="1000px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="generatingQ67">
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"
            >Q67: Export Notification</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="(q67CommentModal = false), (q67Comment = null)"
            ><v-icon>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="3">
              <v-switch
                v-model="q67Request.submit"
                label="Submit to Titan"
                dense
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete
                outlined
                dense
                label="Voyage"
                v-model="q67Request.voyage"
                clearable
                hide-details
                :items="ppecbVoyages"
                item-text="vesselName"
                item-value="voyageNumber"
              >
                <template v-slot:selection="data">
                  <span style="font-size: 12px"
                    >{{ data.item.vesselName }} ({{
                      data.item.voyageNumber
                    }})</span
                  >
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 12px">{{
                      data.item.vesselName
                    }}</v-list-item-title>
                    <v-list-item-subtitle style="font-size: 11px">
                      {{ data.item.voyageNumber }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="3">
              <v-text-field
                :key="nCodeKey"
                outlined
                dense
                hide-details
                label="PPECB N Code"
                v-model="q67Request.ppecbNcode"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                outlined
                dense
                hide-details
                label="Charge Party"
                v-model="q67Request.ppecbInv"
                :items="availableParties"
                @change="setNCode"
                item-text="name"
                item-value="name"
              ></v-select>
            </v-col>
          </v-row>
          <v-textarea
            v-model="q67Comment"
            outlined
            placeholder="Addtional Comments (optional)"
          ></v-textarea>
          <v-row justify="center">
            <v-btn
              @click="generateQ67()"
              :disabled="!q67Request.ppecbNcode || !q67Request.ppecbInv"
              small
              rounded
              color="blue"
              class="mx-1"
              >Generate Q67</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="viewExportNotification"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <PreviewExportNotification
        :data="booking.exportNotificationData"
        @close="viewExportNotification = false"
      />
    </v-dialog>
    <v-dialog
      v-model="confirmExportNotificationModal"
      width="600px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card flat :loading="loadingContainers">
        <v-card-text v-if="exportNotificationContainers.length > 0">
          <!-- <span>Select containers for file:</span> -->
          <v-row align="center" justify="center" class="mt-3">
            <v-col cols="6" class="text-center">
              <v-btn
                small
                outlined
                color="green"
                @click="
                  selectAllFileContainers(filterExportNotificationContainers)
                "
                >Select All</v-btn
              >
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn small outlined color="red" @click="deselectAllContainers()"
                >Deselect All</v-btn
              >
            </v-col>
          </v-row>
          <v-text-field
            class="mt-2"
            placeholder="Search"
            outlined
            dense
            clearable
            prepend-inner-icon="search"
            v-model="searchContainers"
            hide-details
          ></v-text-field>
          <v-list
            dense
            :key="containerKey"
            style="max-height: 50vh; overflow-y: auto"
          >
            <v-list-item
              v-for="container in filterExportNotificationContainers"
              :key="container.id"
              @click="addToContainerSelection(container.id)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ container.ctoNo }}</v-list-item-title>
                <v-list-item-subtitle
                  v-if="container.containerNo"
                  style="font-size: 11px"
                  >{{ container.containerNo }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  v-if="container.sealNo"
                  style="font-size: 11px"
                  >Seal No: {{ container.sealNo }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  v-if="container.LOADING_POINT_1"
                  style="font-size: 11px"
                  ><v-icon x-small color="grey" class="mr-2"
                    >location_on</v-icon
                  >
                  LP1: {{ container.LOADING_POINT_1.description }}
                  {{
                    $Format.formateDate(container.LOADING_POINT_1.date)
                      .isoFormat
                  }}
                  {{ container.LOADING_POINT_1.time }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  v-if="container.LOADING_POINT_2"
                  style="font-size: 11px"
                  ><v-icon x-small color="grey" class="mr-2"
                    >location_on</v-icon
                  >
                  LP2: {{ container.LOADING_POINT_2.description
                  }}{{
                    $Format.formateDate(container.LOADING_POINT_2.date)
                      .isoFormat
                  }}
                  {{ container.LOADING_POINT_2.time }}</v-list-item-subtitle
                >

                <v-list-item-subtitle v-if="container.customerContainerRef">
                  <v-icon small class="pb-1 mr-1" color="grey"
                    >qr_code_2</v-icon
                  >
                  {{ container.customerContainerRef }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                  v-if="selectedContainers.includes(container.id)"
                  color="success"
                  >check_circle_outline</v-icon
                >
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="filterExportNotificationContainers.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey"
                  >No containers found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row justify="center" class="my-2">
            <v-btn
              text
              color="primary"
              :disabled="selectedContainers.length == 0"
              :loading="loadingConfirmExportNotification"
              @click="submitConfirmedeQ67ContainerItems()"
              >Submit</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="previewModal"
      width="1200px"
      :fullscreen="fullscreen || $vuetify.breakpoint.mobile"
    >
      <v-card v-if="item && item.name">
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            {{ item.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="downloadDocument(item)" icon
            ><v-icon small>download</v-icon></v-btn
          >
          <v-btn @click="fullscreen = !fullscreen" icon
            ><v-icon v-if="!fullscreen" small>fullscreen</v-icon
            ><v-icon v-else small>fullscreen_exit</v-icon></v-btn
          >
          <v-btn
            icon
            color="redPop"
            @click="(previewModal = false), (item = {})"
          >
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text
          :style="{
            'max-height': fullscreen ? '90vh' : '75vh',
            'overflow-y': 'auto',
          }"
        >
          <v-row>
            <v-col cols="12" :loading="imageLoading">
              <div v-if="item.type && item.type.includes('image/')">
                <v-img
                  :src="item.url"
                  contain
                  style="max-height: 70vh"
                  @load="imageLoading = false"
                >
                </v-img>
              </div>
              <div v-else-if="item.type == 'application/pdf'">
                <v-row justify="center">
                  <v-col cols="12" class="text-center" v-if="item.url">
                    <div ref="pdfBox">
                      <VuePdfEmbed
                        :width="fullscreen ? 1500 : 900"
                        :source="item.url"
                        @loaded="imageLoading = false"
                      />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-container v-else fill-height>
                <v-row justify="center" height="100%">
                  <div class="text-center">
                    <span
                      >This file is in a format that cannot be previewed.</span
                    >
                    <br /><br />
                    <v-btn
                      color="primary"
                      style="text-transform: none"
                      @click="downloadDocument(item)"
                    >
                      <v-icon class="mr-1">download</v-icon> Download</v-btn
                    >
                  </div>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bookingCommentModal" width="500px">
      <v-card>
        <v-toolbar flat
          ><v-toolbar-title style="font-size: 16px">Add Notes</v-toolbar-title
          ><v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="submitInttraBooking()"
                :loading="submittingBooking"
                color="primary"
              >
                <v-icon small>send</v-icon>
              </v-btn>
            </template>
            <span style="font-size: 12px">Submit Booking</span>
          </v-tooltip>

          <v-btn icon color="redPop" @click="bookingCommentModal = false">
            <v-icon small>close</v-icon>
          </v-btn></v-toolbar
        >
        <v-card-text>
          <v-textarea
            v-model="booking.notes"
            rows="6"
            outlined
            label="Add additional notes"
          ></v-textarea>
          <v-textarea
            v-model="systemNotes"
            rows="6"
            outlined
            label="System notes"
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmCancel"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="confirmCancel = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 14px">
            Are you sure you would like to cancel this booking on Inttra?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmCancel = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="cancelBookingIntegration()"
              :loading="loadingCancel"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color"
      >{{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">
          X
        </v-btn>
      </template></v-snackbar
    >

    <v-snackbar
      v-model="errorSnackbar"
      :timeout="-1"
      color="red"
      :multi-line="true"
    >
      <span style="font-size: 16px"
        >There was an error submitting the export booking to Titan
        (PPECB):</span
      >
      <ul>
        <li v-for="(error, i) in errors" :key="i">
          {{ error }}
        </li>
      </ul>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="errorSnackbar = false">
          X
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="copySnackbar"
      :timeout="2000"
      absolute
      bottom
      color="success"
      center
      text
      style="z-index: 99999"
    >
      Copied to clipboard!
    </v-snackbar>

    <v-snackbar
      v-model="inttraErrorModal"
      color="red"
      :multi-line="true"
      :timeout="-1"
    >
      <span style="font-size: 16px"
        >There was an error submitting the booking to INTTRA:</span
      >
      <ul>
        <li v-for="(error, i) in inttraErrors" :key="i">
          {{ error.code }}: {{ error.message }}
        </li>
      </ul>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="inttraErrorModal = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-model="previewShipmentFile"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <ViewFile
        :key="previewKey"
        v-if="previewShipmentFile && selectedFile && selectedFile.id"
        :fileId="selectedFile.id"
        @close="previewShipmentFile = false"
        @Cancelled="cancelFile"
      />
    </v-dialog>

    <v-dialog
      v-model="createFileError"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-text class="text-left mt-3 pt-3">
          <p style="font-size: 14px">
            Please complete the following before continuing:
          </p>
          <ul>
            <!-- <li v-if="!booking.shipper">Select a Shipper</li>
                        <li v-if="!booking.consignee">Select a Consignee</li> -->
            <li v-if="!booking.regimeCode">Set Regime Code</li>
            <li v-if="!booking.originCountry">Specify an Origin Country</li>
            <li v-if="!booking.destinationCountry">
              Specify a Destination Country
            </li>
            <li v-if="!booking.portOfLoadValue">Port of Load (POL)</li>
            <li v-if="!booking.portOfDischargeValue">
              Port of Discharge (POD)
            </li>
            <li v-if="!booking.finalDestinationValue">Set Final Destination</li>
            <li v-if="!booking.vessel">Select a Vessel</li>
            <li v-if="!booking.mainCarriageConveyanceNumber">Set Voyage No.</li>
            <li v-if="!booking.contractNumber">Specify a Contract No.</li>
            <li v-if="!booking.etd">Specify a Departure Date</li>
            <li v-if="!booking.eta">Specify an Arrival Date</li>
            <li v-if="!booking.carrierReferenceNumber">
              Specify a Carrier Reference Number
            </li>

            <!-- <li v-if="!booking.incoTerm">Specify an Inco Term</li> -->
          </ul>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="blue"
              class="mx-2"
              text
              small
              @click="createFileError = false"
              >Okay</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="createFileDialog"
      :width="fileTab == 0 ? '400px' : '95vw'"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card
        v-if="
          booking &&
          booking.bookingContainers &&
          booking.bookingContainers.length > 0
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            Create File</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-menu
          v-if="
            fileTab == 2 && (((booking.type == 'LOGISTICS' ||
                    booking.type == 'DOCUMENTATION') &&
                    !file.incoTermId) ||
                  booking.contractPartyId !== file.contractPartyId)
          "
          open-on-hover
          bottom
          left
          offset-x
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="grey">
              <v-icon small>save</v-icon>
            </v-btn>
          </template>
          <v-list
            dense
            subheader
            style="
              background: var(--v-component-base) !important;
              background-color: var(--v-component-base) !important;
            "
          >
            <v-subheader style="font-size: 14px">Validation Errors</v-subheader>
            <v-divider></v-divider>
            <v-list-item v-if="!file.incoTermId" style="height: 30px">
              <v-list-item-action class="mr-1">
                <v-icon small color="redPop">fiber_manual_record</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 11px"
                  >Deal Term is required</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="booking.contractPartyId !== file.contractPartyId" style="height: 30px">
              <v-list-item-action class="mr-1">
                <v-icon small color="redPop">fiber_manual_record</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 11px" class="text-wrap"
                  >Contract Owner discrepancy on Booking vs Deal Term</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-else-if="fileTab == 2"
          icon
          @click="(confirmFileCreate = true), (createFileDialog = false)"
          color="primary"
          ><v-icon small>save</v-icon></v-btn
        >
          <v-btn icon color="redPop" @click="createFileDialog = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="fileTab == 0">
          <!-- <span>Select containers for file:</span> -->
          <v-row align="center" justify="center">
            <v-col cols="6" class="text-center">
              <v-btn
                small
                outlined
                color="green"
                @click="selectAllFileContainers(filterContainers)"
                >Select All</v-btn
              >
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn small outlined color="red" @click="deselectAllContainers()"
                >Deselect All</v-btn
              >
            </v-col>
          </v-row>
          <v-text-field
            class="mt-2"
            placeholder="Search"
            outlined
            dense
            clearable
            prepend-inner-icon="search"
            v-model="searchContainer"
            hide-details
          ></v-text-field>
          <v-list
            dense
            :key="containerKey"
            style="max-height: 50vh; overflow-y: auto"
          >
            <v-list-item
              v-for="container in filterContainers"
              :key="container.id"
              @click="addToFile(container.id)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ container.ctoNo }}</v-list-item-title>
                <v-list-item-subtitle v-if="container.containerNo">{{
                  container.containerNo
                }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="container.sealNo"
                  >Seal No: {{ container.sealNo }}</v-list-item-subtitle
                >
                <v-list-item-subtitle v-if="container.buyer"
                  ><v-icon small color="grey" class="mr-2"
                    >shopping_cart</v-icon
                  >
                  <span v-if="container.buyer.alias">{{
                    container.buyer.alias
                  }}</span
                  ><span v-else>{{
                    container.buyer.name
                  }}</span></v-list-item-subtitle
                >
                <v-list-item-subtitle v-if="container.consignee"
                  ><v-icon small color="grey" class="mr-2"
                    >call_received</v-icon
                  >
                  <span v-if="container.consignee.alias">{{
                    container.consignee.alias
                  }}</span
                  ><span v-else>{{
                    container.consignee.name
                  }}</span></v-list-item-subtitle
                >
                <v-list-item-subtitle v-if="container.shipmentFile">
                  <v-icon small class="pb-1 mr-1" color="grey"
                    >description</v-icon
                  >
                  {{ container.shipmentFile.fileNumber }}</v-list-item-subtitle
                >
                <v-list-item-subtitle v-if="container.customerContainerRef">
                  <v-icon small class="pb-1 mr-1" color="grey"
                    >qr_code_2</v-icon
                  >
                  {{ container.customerContainerRef }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                  v-if="selectedContainers.includes(container.id)"
                  color="success"
                  >check_circle_outline</v-icon
                >
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="filterContainers.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 14px; color: grey"
                  >No containers found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row
            justify="center"
            class="my-2"
            v-if="selectedContainers.length > 0"
          >
            <v-btn text color="primary" @click="containerSelectionComplete()"
              >Next</v-btn
            >
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="fileTab == 1">
          <SearchShipmentProfiles
            v-if="!settingShipmentProfile"
            :customerId="booking.customerId"
            :disableClose="true"
            @selected="setFileProfile"
          />
          <v-container style="width: 100%; height: 200px" v-else>
            <v-row justify="center" align="center" style="height: 100%">
              <v-col cols="12" class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <div class="mt-3">Setting Shipment Profile...</div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col cols="12" sm="4" v-if="fileTab == 2">
              <v-list dense>
                <v-subheader style="font-size: 16px"
                  ><v-icon color="secondary" class="mr-2">widgets</v-icon>
                  Containers</v-subheader
                >
                <v-divider></v-divider>
                <v-list-item
                  v-for="container in selectedContainers"
                  :key="container.id"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ container.ctoNo }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="container.containerNo">
                      {{ container.containerNo }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="container.sealNo">
                      {{ container.sealNo }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="4" v-if="file.consigneeProfile">
              <v-list dense>
                <v-subheader style="font-size: 16px"
                  ><v-icon color="secondary" class="mr-2">groups</v-icon>
                  Shipment Profile</v-subheader
                >
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="secondary">handshake</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.consigneeProfile.systemReference }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip @click="fileTab = 1" small>
                      Change Profile <v-icon right small>refresh</v-icon>
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="file.consigneeProfile.shipper">
                  <v-list-item-action>
                    <v-icon color="secondary">directions_boat</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.consigneeProfile.shipper.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Shipper</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="file.consigneeProfile.consignee">
                  <v-list-item-action>
                    <v-icon color="secondary">call_received</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.consigneeProfile.consignee.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Consignee</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="file.consigneeProfile.originCountry">
                  <v-list-item-action>
                    <v-avatar size="32">
                      <v-img
                        contain
                        v-if="
                          file.consigneeProfile.originCountry &&
                          file.consigneeProfile.originCountry.iso2
                        "
                        :src="`https://cdn.loglive.io/flags/4x3/${file.consigneeProfile.originCountry.iso2.toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.consigneeProfile.originCountry.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Origin Country</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="file.consigneeProfile.destinationCountry">
                  <v-list-item-action>
                    <v-avatar size="32">
                      <v-img
                        contain
                        v-if="
                          file.consigneeProfile.destinationCountry &&
                          file.consigneeProfile.destinationCountry.iso2
                        "
                        :src="`https://cdn.loglive.io/flags/4x3/${file.consigneeProfile.destinationCountry.iso2.toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.consigneeProfile.destinationCountry.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Destination Country</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="4" v-if="file && file.consigneeProfile">
              <v-list dense>
                <v-subheader style="font-size: 16px"
                  ><v-icon color="secondary" class="mr-2">groups</v-icon> Deal
                  term <v-spacer></v-spacer>
                  <v-chip v-if="booking.incoTerm" small
                    >Current Setting: {{ booking.incoTerm }}</v-chip
                  >
                </v-subheader>
                <v-divider></v-divider>
                <v-list-item
                  v-for="term in file.consigneeProfile
                    .consigneeProfileIncoTerms"
                  :disabled="term.contractPartyId !== booking.contractPartyId"
                  :key="term.id"
                  @click="setProfileIncoterm(term)"
                >
                  <v-list-item-action>
                    <v-chip>{{ term.incoTerm }}</v-chip>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-subtitle style="text-transform: capitalize">
                      Origin Charge: 
                      {{ term.originTerm }}
                      <v-chip small outlined style="border: none" class="ml-1" v-if="term.originParty">
                        <v-icon small class="mr-1">groups</v-icon>
                         {{ term.originParty.name }}
                      </v-chip>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="text-transform: capitalize">
                      Freight Charge: 
                      {{ term.paymentTerm }} <v-chip small outlined style="border: none" class="ml-1" v-if="term.freightParty">
                        <v-icon small class="mr-1">groups</v-icon>
                         {{ term.freightParty.name }}
                      </v-chip>
                    </v-list-item-subtitle>
                   
                    <v-list-item-subtitle style="text-transform: capitalize">
                      Destination Charge:
                      {{ term.destinationTerm }}
                      <v-chip small outlined style="border: none" class="ml-1" v-if="term.destinationParty">
                        <v-icon small class="mr-1">groups</v-icon>
                         {{ term.destinationParty.name }}
                      </v-chip>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="text-transform: capitalize">
                      Contract Owner:  <v-chip small outlined style="border: none" class="ml-1" v-if="term.contractParty">
                        <v-icon small class="mr-1">article</v-icon>
                         {{ term.contractParty.name }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action :key="incoTermKey">
                    <v-icon
                      v-if="
                        file.incoTermId &&
                        term.id === file.incoTermId &&
                        term.contractPartyId === booking.contractPartyId
                      "
                      color="success"
                      >check_circle_outline</v-icon
                    >
                    <v-tooltip top  v-else-if="term.contractPartyId !== booking.contractPartyId">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                      color="amber">warning</v-icon>
                      </template>
                      <span>Contract Party does not match</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Create file Dialog -->
    <v-dialog
      v-model="confirmFileCreate"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmFileCreate = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 14px">
            Are you sure you would like to create a file with the selected
            configuration?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="(confirmFileCreate = false), (createFileDialog = true)"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="createBookingFile()"
              :loading="loadingCreateFile"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Locations from "./Locations.vue";
import Parties from "./Parties.vue";
import PreviewExportNotification from "../PPECB/PreviewExportNotification.vue";
import ProductServiceModal from "../ProductServices/ProductServiceModal.vue";
import SearchShipmentProfiles from "@/components/SystemComponents/SearchShipmentProfile.vue";
import ViewFile from "../SystemComponents/PreviewFileModal.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  props: {
    booking: {
      type: Object,
      required: true,
    },
    linkedFiles: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    organisations: {
      type: Array,
      required: true,
    },
    containerKey: {
      type: Number,
      required: true,
    },
    productServices: {
      type: Array,
      required: true,
    },
  },
  components: {
    // GmapCustomMarker,
    Locations,
    Parties,
    PreviewExportNotification,
    ProductServiceModal,
    SearchShipmentProfiles,
    ViewFile,
    VuePdfEmbed,
  },
  data: () => ({
    availableShipmentProducts: [],
    bookingCommentModal: false,
    bookingSubmitWarning: false,
    bookingUserModal: false,
    bookingUserSearch: null,
    caProducts: [
      {
        shippingLine: "HPL",
        name: "EXTRAFRESH PLUS",
        description: "EXTRAFRESH PLUS",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "MSK",
        name: "STARCARE",
        description: "STARCARE",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "MSK",
        name: "STARCOOL",
        description: "STARCOOL",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "MSK",
        name: "LIVENTUS",
        description: "LIVENTUS",
        oxygen: 8,
        carbonDioxide: 15,
      },
      {
        shippingLine: "ONE",
        name: "MAXTEND",
        description: "MAXTEND",
        oxygen: 5,
        carbonDioxide: 10,
      },
      {
        shippingLine: "MSC",
        name: "XTENDFR A",
        description: "XTENDFRESH AVOCADOS",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "HPL",
        name: "EXTRAFR B",
        description: "EXTRAFR BERRIES",
        oxygen: 4,
        carbonDioxide: 12,
      },
    ],
    caProductModal: false,
    confirmCancel: false,
    confirmExportNotificationModal: false,
    confirmFileCreate: false,
    containerTypes: [],
    copySnackbar: false,
    createFileDialog: false,
    createFileError: false,
    exportNotificationContainers: [],
    errors: [],
    errorSnackbar: false,
    exportNotificationKey: 500,
    file: {},
    fileTab: 0,
    fullscreen: true,
    generatingQ67: false,
    integrationForms: [],
    imageLoading: false,
    incoTermKey: 1100,
    inttraErrorModal: false,
    inttraErrors: [],
    item: {},
    loadingCancel: false,
    loadingConfirmExportNotification: false,
    loadingContainers: false,
    loadingCreateFile: false,
    loadingVoyages: false,
    orgUsers: [],
    nCodeKey: 600,
    ppecbVoyages: [],
    previewModal: false,
    previewKey: 1000,
    previewShipmentFile: false,
    productKey: 500,
    productServiceModal: false,
    productServiceItem: {},
    q67CommentModal: false,
    q67Comment: null,
    q67Request: {},
    q67RequestModal: false,
    referenceType: {},
    referenceModal: false,
    refreshMap: 400,
    regimeCodeModal: false,
    regimeCodeLoading: false,
    regimeCodes: [],
    regimeCodeSearch: null,
    regimeKey: 100,
    removingSubscriber: null,
    routes: null,
    savingBookingUser: false,
    searchBookingProduct: null,
    searchContainers: null,
    searchContainer: null,
    selectedBookingUsers: [],
    searchContainerProduct: null,
    selectedContainers: [],
    selectedFile: null,
    selectedProductKey: 200,
    searchProfiles: null,
    settingShipmentProfile: false,
    snackbar: {},
    submittingBooking: false,
    systemNotes: null,
    ventSettings: [],
    viewExportNotification: false,
  }),

  computed: {
    availableParties() {
      let parties = [
        "forwarder",
        "shipper",
        "onBehalfShipper",
        "onBehalfForwarder",
      ];
      let result = [];
      for (let party of parties) {
        if (this.booking[party]) {
          result.push(this.booking[party]);
        }
      }
      return result;
    },
    checkContainerCargo() {
      let data = this.booking.bookingContainers ?? [];
      return (
        data.filter(
          (x) =>
            !x.isDeleted &&
            (x.status == "Allocated" ||
              x.status == null ||
              x.status == "Short Shipped") &&
            x.bookingId == this.booking.id
        ).length == 0
      );
    },
    checkContainerProducts() {
      let data = this.booking.bookingContainers ?? [];
      let concatResult = [].concat.apply(
        [],
        data
          .filter(
            (x) =>
              !x.isDeleted &&
              (x.status == "Allocated" ||
                x.status == null ||
                x.status == "Short Shipped") &&
              x.bookingId == this.booking.id
          )
          .map((x) => x.containerProducts)
      );
      return concatResult.length == 0;
    },
    filterAvailableContainerProducts() {
      let result = this.productServices.filter((x) => x.level == "CONTAINER");
      if (this.searchContainerProduct) {
        result = result.filter((x) =>
          x.name
            .toLowerCase()
            .includes(this.searchContainerProduct.toLowerCase())
        );
      }
      return result;
    },
    filterAvailableShipmentProducts() {
      let result = this.productServices.filter((x) => x.level == "SHIPMENT");
      if (this.searchBookingProduct) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchBookingProduct.toLowerCase())
        );
      }
      return result;
    },
    filterContainers() {
      let result = this.booking.bookingContainers ?? [];
      if (this.searchContainers) {
        result = result.filter(
          (x) =>
            (x.containerNo &&
              x.containerNo
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase())) ||
            (x.sealNo &&
              x.sealNo
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase())) ||
            (x.LOADING_POINT_1 &&
              x.LOADING_POINT_1.description
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase())) ||
            (x.LOADING_POINT_2 &&
              x.LOADING_POINT_2.description
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase())) ||
            (x.ctoNo &&
              x.ctoNo
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase()))
        );
      }
      return result;
    },
    filterExportNotificationContainers() {
      let result = this.exportNotificationContainers ?? [];
      if (this.searchContainers) {
        result = result.filter(
          (x) =>
            (x.containerNo &&
              x.containerNo
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase())) ||
            (x.sealNo &&
              x.sealNo
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase())) ||
            (x.LOADING_POINT_1 &&
              x.LOADING_POINT_1.description
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase())) ||
            (x.LOADING_POINT_2 &&
              x.LOADING_POINT_2.description
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase())) ||
            (x.ctoNo &&
              x.ctoNo
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase()))
        );
      }
      return result;
    },
    filterRegimeCodes() {
      let result = this.regimeCodes;
      if (this.regimeCodeSearch) {
        result = result.filter((x) =>
          x.code.toLowerCase().includes(this.regimeCodeSearch.toLowerCase())
        );
      }
      return result;
    },
    filteredUsers() {
      let result = this.orgUsers;
      result = result.filter(
        (x) => !this.booking.subscribers.map((y) => y.userId).includes(x.userId)
      );
      if (this.bookingUserSearch) {
        result = result.filter(
          (x) =>
            x.user.firstname
              .toLowerCase()
              .includes(this.bookingUserSearch.toLowerCase()) ||
            x.user.surname
              .toLowerCase()
              .includes(this.bookingUserSearch.toLowerCase()) ||
            x.user.emailAddress
              .toLowerCase()
              .includes(this.bookingUserSearch.toLowerCase())
        );
      }
      result.sort((a, b) => a.user.firstname.localeCompare(b.user.firstname));
      return result;
    },
    reeferShipment() {
      let data = this.booking.bookingContainers ?? []
      let containers = data.filter(
        (x) =>
          !x.isDeleted &&
          (x.status == "Allocated" || x.status == null || x.status == "Short Shipped") &&
          x.bookingId == this.booking.id
      );
      let findType = this.containerTypes.find(
        (x) =>
          containers.map((y) => y.containerTypeCode).includes(x.typeCode) &&
          x.typeCategory == "Reefer"
      );
      return findType ? true : false;
    },
  },
  async created() {
    this.containerTypes = await this.$API.listContainerTypes();
    this.getOrganisationUsers();
    this.getBookingFormResponses();
  },
  methods: {
    addBookingUser(userId) {
      let find = this.selectedBookingUsers.findIndex((x) => x == userId);
      if (find == -1) {
        this.selectedBookingUsers.push(userId);
      } else {
        this.selectedBookingUsers.splice(find, 1);
      }
    },
    addProductService() {
      if (!this.booking.productServiceSales) {
        this.booking.productServiceSales = [];
      }
      this.productServiceItem = {
        index: this.booking.productServiceSales.length,
        level: "SHIPMENT",
      };
      this.productServiceModal = true;
      this.productKey ++
    },
    addToFile(id) {
      let index = this.selectedContainers.findIndex((x) => x == id);
      if (index != -1) {
        this.selectedContainers.splice(index, 1);
      } else {
        this.selectedContainers.push(id);
      }
    },
    calculateTotalEmissions() {
      let containers = [].concat.apply(
        [],
        this.booking.bookingContainers.map((x) => x.containerTypeCode)
      );
      let totalTEU = 0;
      for (let containerType of containers) {
        let findTEU = this.containerTypes.find(
          (x) => x.typeCode == containerType
        );
        if (findTEU) {
          totalTEU += findTEU.teu;
        }
      }
      return (totalTEU * this.booking.teuCarbonEmission).toFixed(4);
    },
    async calculateRoutes() {
      if (this.booking && this.booking.vessel && this.booking.sailingSchedule) {
        let routes = await this.$API.getBookingEmissions(this.booking.id, {
          params: {
            vesselId: this.booking.vessel.id,
            sailingScheduleId: this.booking.sailingScheduleId,
          },
        });
        this.routes = routes;
      }
    },
    async cancelBookingIntegration() {
      this.loadingCancel = true;
      try {
        let result = await this.$API.cancelBookingIntegration(this.booking.id);
        if (result && result.message) {
          this.$message.success(result.message);
        } else {
          this.$message.error(
            "Failed to cancel booking. Please try again later."
          );
        }
        this.loadingCancel = false;
        this.confirmCancel = false;
      } catch (e) {
        this.$message.error(
          "Failed to cancel booking. Please try again later."
        );
        this.loadingCancel = false;
      }
    },
    cancelFile() {
      let index = this.linkedFiles.findIndex(
        (x) => x.id == this.selectedFile.id
      );
      if (index > -1) {
        this.linkedFiles.splice(index, 1);
      }
      this.$emit("refreshContainers");

      this.previewShipmentFile = false;
    },
    cargoSummary() {
      let result = [];
      if (this.booking && this.booking.bookingContainers) {
        let containers = this.booking.bookingContainers.filter(
          (x) =>
            !x.isDeleted &&
            (x.status == "Allocated" ||
              x.status == null ||
              x.status == "Short Shipped") &&
            x.bookingId == this.booking.id
        );
        containers.forEach((x) => {
          let containerProducts = x.containerProducts.map((y) => y.productId);
          containerProducts = containerProducts.sort((a, b) => a - b);
          x.transportType = x.isPrivateHaulage
            ? "Merchant"
            : x.isCarrierHaulage
            ? "Carrier"
            : x.isRailage
            ? "Rail"
            : "Other";
          x.hash =
            x.gensetRequired +
            x.containerTypeCode +
            x.verificationMethod +
            x.transportCoordinatorId +
            x.transportType +
            containerProducts.join("_");
        });
        let uniqueCombinations = [...new Set(containers.map((x) => x.hash))];
        for (let i = 0; i < uniqueCombinations.length; i++) {
          let filtered = containers.filter(
            (x) => x.hash == uniqueCombinations[i]
          );
          let products = [].concat.apply(
            [],
            containers.map((x) => x.containerProducts)
          );
          let detail = filtered[0];
          let obj = {
            count: filtered.length,
            containerType: detail.containerTypeCode,
            products: detail.containerProducts
              .map((x) => this.$Format.capitalizeFirstLetter(x.product.name))
              .join(", "),
            method: detail.verificationMethod,
            pallets: products
              .filter((x) => x.pallets)
              .map((x) => x.pallets)
              .reduce((a, b) => a + b, 0),
            units: products
              .filter((x) => x.quantity)
              .map((x) => x.quantity)
              .reduce((a, b) => a + b, 0),
            transportCoordinator: detail.transportCoordinator ?? null,
            transportType: detail.isPrivateHaulage
              ? "Merchant"
              : detail.isCarrierHaulage
              ? "Carrier"
              : detail.isRailage
              ? "Rail"
              : "Unknown",
            type: detail.transportType,
            hash: uniqueCombinations[i],
          };
          result.push(obj);
        }
      }
      return result;
    },
    confirmExportNotificationItems() {
      this.selectedContainers = [];
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      this.exportNotificationContainers = this.booking.bookingContainers.filter(
        (x) =>
          !x.isDeleted &&
          x.status != "Ghost" &&
          x.status != "Cancelled" &&
          x.bookingId == this.booking.id &&
          x.exportNotificationReference &&
          x.exportNotificationStatus == "PENDING" &&
          x.LOADING_POINT_1 &&
          x.LOADING_POINT_1.date &&
          new Date(x.LOADING_POINT_1.date).setHours(0, 0, 0, 0) > today
      );
      if (this.exportNotificationContainers.length > 0) {
        this.confirmExportNotificationModal = true;
      } else {
        this.$message.success(
          "All containers already have export notificatons!"
        );
      }
    },
    copyContent(data) {
      navigator.clipboard.writeText(data);
      this.copySnackbar = true;
    },
    deselectAllFileContainers() {
      this.selectedContainers = [];
    },
    selectAllFileContainers(containers) {
      this.selectedContainers = containers.map((x) => x.id);
    },
    viewFileDialog() {
      //  if (this.bookingChange) {
      //    this.saveChangeSnackbar = true;
      // } else {
      this.selectedContainers = [];
      this.fileTab = 0;
      let containers = this.booking.bookingContainers.filter(
        (x) =>
          !x.shipmentFileId &&
          !x.isDeleted &&
          x.bookingId == this.booking.id &&
          x.status != "Ghost" &&
          x.status != "Cancelled"
      );
      let findType = this.containerTypes.some(
        (x) =>
          containers.map((y) => y.containerTypeCode).includes(x.typeCode) &&
          x.typeCategory == "Reefer"
      );
      if (
        this.booking.movementType !== "LOCAL" &&
        (!this.booking.originCountry ||
        !this.booking.destinationCountry ||
        findType
          ? !this.booking.regimeCode
          : false ||
            !this.booking.portOfLoadValue ||
            !this.booking.portOfDischargeValue ||
            !this.booking.finalDestinationValue ||
            !this.booking.vessel ||
            !this.booking.contractNumber ||
            !this.booking.mainCarriageConveyanceNumber ||
            !this.booking.carrierReferenceNumber ||
            !this.booking.etd ||
            !this.booking.eta)
        // || !this.booking.incoTerm
      ) {
        this.createFileError = true;
      } else {
        if (this.booking.consigneeProfile) {
          this.selectedContainers = this.booking.bookingContainers
            .filter(
              (x) =>
                !x.shipmentFileId &&
                !x.isDeleted &&
                x.bookingId == this.booking.id &&
                x.status != "Ghost" &&
                x.status != "Cancelled"
            )
            .map((x) => x.id);

          // this.booking.bookingContainers.filter(x => !x.isDeleted).map(x => x.id)
          this.file = {
            consigneeProfileId: this.booking.consigneeProfileId,
            incoTermId: this.booking.dealTermId,
          };
          // this.confirmFileCreate = true
          this.createFileDialog = true;
        } else {
          this.file = {};
          this.createFileDialog = true;
        }
      }
      // }
    },
    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    editSubscribers() {
      this.bookingUserSearch = null;
      this.bookingUserModal = true;
    },
    formatExportNotificationStatus(status) {
      switch (status) {
        case "PENDING":
          return "Pending";
        case "COMPLETED":
          return "Completed";
        case "INPROGRESS":
          return "In Progress";
      }
    },
    async generateQ67() {
      this.generatingQ67 = true;
      let result = await this.$API.generateQ67({
        bookingId: this.booking.id,
        comment: this.q67Comment,
        ...this.q67Request,
      });
      let findForm = this.integrationForms.find(
        (x) => x.countryIntegrationId == 9 || x.countryIntegrationId == 11
      );
      if (
        result &&
        result.exportNotificationData &&
        result.exportNotificationData.response &&
        result.exportNotificationData.response.value
      ) {
        this.errors = result.exportNotificationData.response.value.messages;
        this.errorSnackbar = true;
      }

      if (
        result &&
        result.exportNotificationData &&
        result.exportNotificationData.repsonse &&
        result.exportNotificationData.response.createLineItemsError
      ) {
        this.errors = [
          result.exportNotificationData.response.createLineItemsError,
        ];
        this.errorSnackbar = true;
      }
      if (
        result.exportNotificationData &&
        result.exportNotificationData.response &&
        result.exportNotificationData.response.ppecbBookingReference
      ) {
        let clone = { ...this.booking };
        clone.exportNotificationReference =
          result.exportNotificationData.response.ppecbBookingReference;
        clone.exportNotificationStatus =
          result.exportNotificationData.response.status;
        this.$emit("bookingUpdate", clone);
        this.exportNotificationKey++;
        let findContainers = this.booking.bookingContainers.filter(
          (x) =>
            !x.isDeleted &&
            x.bookingId == this.booking.id &&
            x.status != "Ghost" &&
            x.status != "Cancelled"
        );
        if (
          result.exportNotificationData.response.lineItems &&
          typeof result.exportNotificationData.response.lineItems != "object"
        ) {
          findContainers.forEach((x) => {
            x.exportNotificationStatus =
              result.exportNotificationData.response.status;
          });
        }
      }
      if (findForm && findForm.answers) {
        if (typeof findForm.answers == "string") {
          findForm.answers = JSON.parse(findForm.answers);
        }
        let parties = [
          "forwarder",
          "shipper",
          "onBehalfShipper",
          "onBehalfForwarder",
        ];
        let available = [];
        for (let party of parties) {
          if (this.booking[party]) {
            this.booking[party].role = party;
            available.push(this.booking[party]);
          }
        }
        if (this.q67Request.ppecbInv) {
          let findParty = available.find(
            (x) => x.name == this.q67Request.ppecbInv
          );
          if (findParty) {
            findForm.answers["CHARGE_PARTY"] = findParty.role;
          }
        }
        findForm.answers["PPECB_N_CODE"] = this.q67Request.ppecbNcode ?? "";
        findForm.answers["COMMENT"] = this.q67Request.comments ?? "";
        await this.$API.updateBookingForms(findForm);
      }

      if (result) {
        let clone = { ...this.booking };
        clone.q67 = result.q67;
        clone.q67Id = result.q67Id;
        clone.q67Excel = result.q67Excel;
        clone.q67ExcelId = result.q67ExcelId;

        this.q67CommentModal = false;
        this.q67Comment = null;
        this.$emit("bookingUpdate", clone);
        this.$message({
          type: "success",
          message: `Q67 Generated Successfully`,
        });
      }
      this.generatingQ67 = false;
      this.$emit("refreshContainers");
    },
    async getBookingFormResponses() {
      let findBooking = this.integrationForms.find(
        (x) => x.bookingId == this.booking.id
      );
      if (this.integrationForms.length == 0 || !findBooking) {
        this.integrationForms = await this.$API.getBookingForms(
          this.booking.id
        );
      }
    },
    async submitInttraBooking() {
      this.submittingBooking = true;
      let result = await this.$API.submitBooking(this.booking.id, {
        users: this.booking.subscribers.map((x) => x.user.emailAddress),
        notes: this.booking.notes,
        systemNotes: this.systemNotes,
      });
      if (result.integrationReference) {
        let clone = { ...this.booking };
        clone.integrationReference = result.integrationReference;
        clone.state = result.state;
        this.$emit("bookingUpdate", clone);
        this.$message.success("Successfully submitted to Inttra!");
      } else {
        this.inttraErrors = result.responseData.message ?? [];
        this.inttraErrorModal = true;
      }
      this.bookingCommentModal = false;
      this.submittingBooking = false;
    },
    async checkSubmitBooking() {
      let containers = this.booking.bookingContainers.filter(
        (x) =>
          !x.isDeleted &&
          (x.status == "Allocated" ||
            x.status == null ||
            x.status == "Short Shipped") &&
          x.bookingId == this.booking.id
      );

      let products = [].concat.apply(
        [],
        containers.map((x) => x.containerProducts)
      );
      if (
        !this.booking.contractNumber ||
        !this.booking.finalDestinationValue ||
        !this.booking.portOfLoadValue ||
        !this.booking.forwarder ||
        !this.booking.shipper ||
        !this.booking.mainCarriageConveyanceNumber ||
        !this.booking.vessel ||
        this.reeferShipment
          ? !this.booking.regime
          : false || containers.length == 0 || products.length == 0
      ) {
        this.bookingSubmitWarning = true;
      } else {
        if (!this.booking.notes) {
          this.booking.notes = "";
        }
        this.systemNotes = "";
        let isSteri = this.booking.regime && this.booking.regime.steri;
        if (this.booking.caProduct) {
          this.systemNotes +=
            "Controlled Atmosphere: " + this.booking.caProduct + ". ";
        }
        if (this.booking.vents) {
          this.systemNotes += "Vent Settings: " + this.booking.vents + ". ";
        }
        if (isSteri) {
          this.systemNotes += "\nNote FULL STERI Product. ";
          if (
            this.booking.destinationCountry &&
            this.booking.destinationCountry.iso2 == "IL"
          ) {
            let alreadyExists = this.booking.notes
              .toLowerCase()
              .includes("origin of fruit");
            if (!alreadyExists) {
              this.booking.notes += "\nOrigin of Fruit: ";
            }
          }
        }
        let isCarrierHaulage = containers.some((x) => x.isCarrierHaulage);
        if (isCarrierHaulage) {
          this.systemNotes += "\nPlease arrange CARRIER HAULAGE. ";
          let uniqueLoadingPoints = [
            ...new Set(
              containers
                .filter((x) => x.LOADING_POINT_1)
                .map((x) => x.LOADING_POINT_1.description)
            ),
          ];
          uniqueLoadingPoints =
            "\nLoading Points: " +
            uniqueLoadingPoints.map(
              (x) =>
                x +
                ` (${containers
                  .filter((y) => y.LOADING_POINT_1.description == x)
                  .map((y) => y.ctoNo)
                  .join(", ")})`
            );
          this.systemNotes += uniqueLoadingPoints;
        }
        this.selectedBookingUsers = [];
        this.bookingCommentModal = true;
      }
    },
    async checkSubmitUpdateBooking() {
      let containers =
        this.booking.bookingContainers.filter(
          (x) =>
            !x.isDeleted &&
            (x.status == "Allocated" ||
              x.status == null ||
              x.status == "Short Shipped") &&
            x.bookingId == this.booking.id
        ).length > 0;
      if (
        !this.booking.contractNumber ||
        !this.booking.finalDestinationValue ||
        !this.booking.portOfLoadValue ||
        !this.booking.mainCarriageConveyanceNumber ||
        !this.booking.vessel ||
        !this.booking.regime ||
        !containers
      ) {
        this.bookingSubmitWarning = true;
      } else {
        this.submittingBooking = true;
        let result = await this.$API.submitBookingUpdate(this.booking.id);
        if (result.success) {
          this.booking.integrationChange = false;
          this.$message.success("Successfully sent update to Inttra!");
        } else {
          this.$message.error("An error occurred!");
        }
        this.submittingBooking = false;
      }
    },
    async createBookingFile() {
      this.loadingCreateFile = true;
      let obj = {
        consigneeProfileId: this.file.consigneeProfileId,
        rmsVesselVoyageId: this.booking.rmsVesselVoyaegId,
        incoTermId: this.file.incoTermId,
        term: this.file.term,
        bookingId: this.booking.id,
        customerId: this.booking.customerId,
        containers: this.selectedContainers.map((x) => x.id),
      };
      let result = await this.$API.createBookingFile(obj);
      this.$message.success("File Created Successfully");
      this.$emit("refreshContainers");
      this.loadingCreateFile = false;
      this.confirmFileCreate = false;
      this.createFileDialog = false;
    },
    editReference(type, label) {
      this.referenceType = { field: type, label: label };
      this.referenceModal = true;
    },

    async getRegimeCodes(modal = false) {
      this.regimeCodeModal = modal;
      this.regimeCodeLoading = true;
      let params = {};
      if (this.booking.destinationCountry) {
        params.countryCode = this.booking.destinationCountry.iso2;
      }
      if (
        this.booking &&
        this.booking.bookingContainers &&
        this.booking.bookingContainers.length > 0
      ) {
        let products = [].concat.apply(
          [],
          this.booking.bookingContainers.map((x) => x.containerProducts).map((y) => y.productId).filter(Boolean)
        );
        params.productCode = products
      }
      this.regimeCodes = await this.$API.getRegimeCodes({
        params: params,
      });
      this.regimeCodeLoading = false;
    },
    containerSelectionComplete() {
      this.selectedContainers = this.selectedContainers.map((x) =>
        this.booking.bookingContainers.find((y) => y.id == x)
      );
      if (this.booking.consigneeProfile) {
        this.file.consigneeProfile = this.booking.consigneeProfile;
        this.file.consigneeProfileId = this.booking.consigneeProfileId;
        if (this.booking.dealTerm) {
          this.file.dealTerm = this.booking.dealTerm;
          this.file.dealTermId = this.booking.dealTermId;
          this.file.contractPartyId = this.booking.dealTerm.contractPartyId;
        } else if (
          this.file.consigneeProfile.consigneeProfileIncoTerms.length > 0
        ) {
          this.file.dealTerm =
            this.file.consigneeProfile.consigneeProfileIncoTerms[0];
          this.file.dealTermId =
            this.file.consigneeProfile.consigneeProfileIncoTerms[0].id;
        }

        this.fileTab = 2;
      } else {
        this.fileTab = 1;
      }
    },
    async getOrganisationUsers() {
      this.orgUsers = await this.$API.getOrganisationUsers();
    },
    async getPPECBVoyages() {
      this.loadingVoyages = true;
      this.ppecbVoyages = await this.$API.getPPECBVoyages();
      if (this.booking.vessel) {
        let findVessel = this.ppecbVoyages.find(
          (x) =>
            x.vesselName.toLowerCase() == this.booking.vessel.name.toLowerCase()
        );
        if (findVessel) {
          this.q67Request.voyage = findVessel.voyageNumber;
        }
      }
      this.loadingVoyages = false;
    },
    async refreshBookingTasks() {
      let tasks = await this.$API.getBookingTasks(this.booking.id);
    },
    removeRegime() {
      this.$confirm("Are you sure you want to remove the regime?", "Confirm", {
        center: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "plain",
      }).then(async () => {
        this.booking.regime = null;
        this.booking.regimeCode = null;
        this.booking.regimeId = null;

        this.regimeKey++;
        this.$emit("bookingChange");
      });
    },
    async removeSubscriber(id) {
      this.removingSubscriber = id;
      let result = await this.$API.updateBookingSubscriber({
        id: id,
        isActive: false,
        isDeleted: true,
      });
      this.$emit("removeSubscriber", id);
      this.$message.success("Successfully removed subscriber!");
      this.removingSubscriber = null;
    },
    async saveBookingSubscriber() {
      this.savingBookingUser = true;
      let request = this.selectedBookingUsers.map((x) => ({
        userId: x,
        bookingId: this.booking.id,
      }));
      let result = await this.$API.createBookingSubscriber({ batch: request });
      if (result && result.length) {
        for (let user of result) {
          let findUser = this.orgUsers.find((x) => x.userId == user.userId);
          user.user = findUser.user;
        }
        this.$emit("setSubscribers", result);
      }
      this.$message.success("Successfully added subscribers!");
      this.bookingUserModal = false;
      this.selectedBookingUsers = [];
      this.savingBookingUser = false;
    },
    selectedBookingProduct(id) {
      let findProduct =
        this.booking.productServiceSales &&
        this.booking.productServiceSales.find((x) => x.id == id);
      return findProduct ? true : false;
    },
    async selectBookingProduct(product) {
      if (!product.selected) {
        product.selected = true;
      } else {
        product.selected = false;
      }
      if (this.selectedBookingProduct(product.productServiceId)) {
        let findProduct = this.booking.productServiceSales.findIndex(
          (x) => x.id == product.id
        );
        this.booking.productServiceSales.splice(findProduct, 1);
        await this.$API.updateProductServiceSale(product.id, {
          isActive: false,
          isDeleted: true,
        });
      } else {
        let create = await this.$API.createProductServiceSale({
          bookingId: this.booking.id,
          productServiceId: product.id,
          isActive: true,
          isDeleted: false,
        });
        this.booking.productServiceSales.push({
          ...create,
          productService: product,
        });
      }
      this.selectedProductKey++;
    },
    setCASettings() {
      if (!this.booking.bookingContainerItems) {
        this.booking.bookingContainerItems = [];
      }
      this.booking.bookingContainerItems.forEach((x) => {
        x.oxygen = this.booking.oxygen;
        x.carbonDioxide = this.booking.carbonDioxide;
      });
      this.$emit("bookingChange");
    },
    async setCAProduct() {
      this.booking.vents =
        this.booking.caProduct && this.booking.caProduct != "MAXTEND"
          ? "Closed"
          : this.booking.caProduct == "MAXTEND"
          ? "MAXTEND"
          : this.booking.vents;
      if (this.booking.caProduct) {
        let findProduct = this.caProducts.find(
          (x) => x.name == this.booking.caProduct
        );
        this.booking.oxygen = findProduct.oxygen;
        this.booking.carbonDioxide = findProduct.carbonDioxide;
      } else {
        this.booking.oxygen = null;
        this.booking.carbonDioxide = null;
      }
      if (!this.booking.bookingContainerItems) {
        this.booking.bookingContainerItems = [];
      }
      this.booking.bookingContainerItems.forEach((x) => {
        if (this.booking.caProduct) {
          if (this.booking.caProduct != "MAXTEND") {
            x.vents = "Closed";
          }
          x.caProduct = this.booking.caProduct;
          x.controlledAtmosphere = true;
          let findProduct = this.caProducts.find(
            (y) => y.name == this.booking.caProduct
          );
          if (findProduct) {
            x.oxygen = findProduct.oxygen;
            x.carbonDioxide = findProduct.carbonDioxide;
            x.nitrogen = 0;
          }
        } else {
          x.caProduct = null;
          x.controlledAtmosphere = false;
        }
      });
      this.caProductModal = false;
      this.$emit("bookingChange");
    },
    setNCode() {
      let find = this.availableParties.find(
        (x) => x.name == this.q67Request.ppecbInv
      );
      if (find && find.nNumber) {
        this.q67Request.ppecbNcode = find.nNumber;
        this.nCodeKey++;
      }
    },
    setFileProfile(profile) {
      this.settingShipmentProfile = true;
      this.file.consigneeProfile = profile;
      this.file.consigneeProfileId = profile.id;
      if (profile.consigneeProfileIncoTerms.length > 0) {
        if (this.booking.incoTerm) {
          let filterProfiles = profile.consigneeProfileIncoTerms.filter(
            (x) => x.contractOwner === this.booking.contractOwner
          );

          let find = filterProfiles.find(
            (x) => x.incoTerm === this.booking.incoTerm
          );
          if (find) {
            this.setProfileIncoterm(filterProfiles);
          } else {
            this.setProfileIncoterm(profile.consigneeProfileIncoTerms[0]);
          }
        } else {
          let filterProfiles = profile.consigneeProfileIncoTerms.filter(
            (x) => x.contractOwner === this.booking.contractOwner
          );

          console.log("find", find);
          this.setProfileIncoterm(filterProfiles[0]);
        }
      } else {
        this.file.term = null;
        this.file.incoTermId = null;
      }
      this.settingShipmentProfile = false;
      this.fileTab = 2;
    },
    setProfileIncoterm(term) {
      if(term){
        if (!Array.isArray(term)) {
        this.file.contractPartyId = term.contractPartyId;
        this.file.incoTermId = term.id;
        this.file.term = term.incoTerm;
        this.incoTermKey++;
      } else if (term.length === 1) {
        this.file.contractPartyId = term[0].contractPartyId;
        this.file.incoTermId = term[0].id;
        this.file.term = term[0].incoTerm;
        this.incoTermKey++;
      } else if (term.length > 1) {
        this.file.contractPartyId = null;
        this.file.incoTermId = null;
        this.file.term = null;
        this.incoTermKey++;
      }
      }
     
    },
    async setRegimeCode(regime) {
      this.booking.regimeCode = regime.code;
      this.booking.regime = regime;
      this.booking.regimeId = regime.id;
      if (this.booking.regimeId) {
        let ventSettings = await this.$API.getRegimeVent(this.booking.regimeId);
        if (ventSettings.length > 0) {
          this.ventSettings = [
            // "Closed",
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ].filter((x) => ventSettings.map((y) => y.ventCode).includes(x));
        } else {
          this.ventSettings = [
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ];
        }
      } else {
        this.ventSettings = [
          "5",
          "10",
          "15",
          "20",
          "25",
          "28",
          "30",
          "35",
          "40",
          "50",
          "Closed",
          "Fully Open",
          "CA",
          "MA",
          "Auto",
        ];
      }
      if (
        !this.booking.vents ||
        !this.ventSettings.includes(this.booking.vents)
      ) {
        this.booking.vents = this.ventSettings[0];
      }
      this.regimeCodeModal = false;
      this.$emit("bookingChange");
    },
    setProductSelection(selection){
      if(this.productServiceItem.level == 'SHIPMENT'){
        this.booking.productServiceSales = selection
      } else if(this.productServiceItem.level == 'CONTAINER'){
        this.containerItem.productServiceSales = selection
      }
    },
    async submitConfirmedeQ67ContainerItems() {
      this.loadingConfirmExportNotification = true;
      let result = await this.$API.confirmExportNotificationItems({
        bookingId: this.booking.id,
        containers: this.selectedContainers,
      });
      if (result.status == "Success") {
        this.$message.success("Containers Successfully Confirmed!");
        this.booking.exportNotificationStatus = result.bookingStatus;

        this.exportNotificationKey++;
      } else {
        this.snackbar = {
          text: result.message,
          timeout: -1,
          color: "red",
          show: true,
        };
      }
      this.$emit("refreshContainers");
      this.confirmExportNotificationModal = false;
      this.loadingConfirmExportNotification = false;
      this.selectedContainers = [];
    },
    viewQ67() {
      let findForm = this.integrationForms.find(
        (x) => x.countryIntegrationId == 9 || x.countryIntegrationId == 11
      );
      this.q67Request = {};
      if (findForm && findForm.answers) {
        if (findForm.answers["PPECB_N_CODE"]) {
          this.q67Request.ppecbNcode = findForm.answers["PPECB_N_CODE"];
        }
        if (
          findForm.answers["CHARGE_PARTY"] &&
          this.booking[findForm.answers["CHARGE_PARTY"]]
        ) {
          this.q67Request.ppecbInv =
            this.booking[findForm.answers["CHARGE_PARTY"]].name;
          if (this.booking[findForm.answers["CHARGE_PARTY"]].nNumber) {
            this.q67Request.ppecbNcode =
              this.booking[findForm.answers["CHARGE_PARTY"]].nNumber;
          }
        }

        if (findForm.answers["COMMENT"]) {
          this.q67Request.comments = findForm.answers["COMMENT"];
        }
      }
      if (!this.q67Request.ppecbInv) {
        let findOrg = this.availableParties.find(
          (x) => x.id == this.$store.state.currentOrg.id
        );
        if (findOrg) {
          this.q67Request.ppecbInv = findOrg.name;
        }
      }
      this.q67Request.submit = false;
      this.setNCode();
      this.getPPECBVoyages();
      this.q67CommentModal = true;
    },
    viewDocument(item) {
      this.item = item;
      if (
        this.item.type &&
        (this.item.type.includes("image/") ||
          this.item.type.includes("application/pdf"))
      ) {
        this.imageLoading = true;
      }
      this.previewModal = true;
    },
    viewExportNotificationData(data) {
      this.viewExportNotification = true;
    },
    viewShipmentFile(file) {
      this.selectedFile = file;
      this.previewKey++;
      this.previewShipmentFile = true;
    },
  },
};
</script>
<style scoped>
* {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.tabContent ::v-deep .v-tabs-bar__content {
  background: var(--darkLayer) !important;
}
.infoCards {
  background: var(--lighterLayer) !important ;
  contain: content;
  border-radius: 10px;
}
.innerContent {
  max-height: calc(100vh - 36px - 30px) !important;
  height: 100%;
  width: 100%;
  contain: content;
  overflow-y: auto;
}
.pulse-hazardous {
  animation: pulse-hazardous 1.5s infinite;
  z-index: 10;
}

@keyframes pulse-hazardous {
  0% {
    border-radius: 50%;
    box-shadow: 0 0 0 0px #0e871e;
  }

  100% {
    border-radius: 50%;
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
}
</style>